@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Mail Sans Roman';
  src: url('./assets/fonts/MailSansRoman-Light.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-Light.woff') format('woff');
  font-weight: 300; 
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src: local('Mail Sans Roman'), 
  url('./assets/fonts/MailSansRoman-Regular.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src:local('Mail Sans Roman'), 
   url('./assets/fonts/MailSansRoman-Medium.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-Medium.woff') format('woff');
  font-weight: 500; /* Normal weight */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src: local('Mail Sans Roman'), 
  url('./assets/fonts/MailSansRoman-Bold.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-DemiBold.woff') format('woff');
  font-weight: 600; /* Normal weight */
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Mail Sans Roman';
  src: local('Mail Sans Roman'), 
  url('./assets/fonts/MailSansRoman-DemiBold.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-DemiBold.woff') format('woff');
  font-weight: 700; /* Normal weight */
  font-style: normal;
  font-display: swap;
}


code {
  font-family: 'Mail Sans Roman', sans-serif;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mail Sans Roman', sans-serif;
}

body {
margin: 0;
font-family:  'Mail Sans Roman', sans-serif;
overflow-x: hidden !important;


color:#1D2939;


}

