

h1 {
  font-size: 60px  ;
  font-weight: 700;

  
}


h2 {
  font-size: 50px ;
  font-weight: 600; 
}

h3 {
  font-size: 24px ;
  font-weight: 600 ;

  
}


h4 {
  font-size: 20px ;
  font-weight: 500 ;
}



h5 {
  font-size: 18px ;
  font-weight: 500 ;
}


h6 {
  font-size: 16px ;
  font-weight: 400 ;
  
}

p, .form-label {
  font-size: 14px ;
  font-weight: 400 ;
}
a{
    text-decoration: none;
}

.hero-section{
  margin-bottom: 120px;
}

input::placeholder, .form-control::placeholder{
  color: #b0b0b0;
  font-weight: 500;
}

.btn{
  font-family: 'Poppins';
  font-weight: 600;
  padding:10px 20px;
  font-size: 18px;
}

.btn-primary{
  background-color: #469B12;
  color: #ffffff;
  border: none;
  
  
}

.btn-outline-primary{
color: #469B12;
  background-color: none;
  border: 1px solid #469B12 !important;
  
  
}



.btn-primary:hover, .btn-outline-secondary:hover{
  background-color:rgba(70, 155, 18, 0.2) !important;
}

.btn-outline-primary:hover{
  background-color: #469B12;
  color: #ffffff;
  border: none;
}

.btn-outline-dark{
  background-color: none;
  color: #1D2939;
  border: 1px solid #1D2939;
}


.nav-link{
  color:'#1D2939';
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 18px;
}

.nav-link:hover, .active {
color: #1D2939;
text-decoration: underline;
font-weight: 700;
}

.actual-navbar{
  margin-left: 5rem;
  margin-right: 5rem;
}



/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-container::-webkit-scrollbar {
  display: none;
}
.text-scroll-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.scrolling-text {
  display: flex;
  width: max-content; /* Allow content to stretch beyond container */
  animation: scroll-left 10s linear infinite;
}

.text-group {
  display: flex;
  gap: 2rem;
  flex-shrink: 0;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-30%); /* Scroll until half of the content */
  }
}



.custom-carousel .carousel-indicators .active {

  background: #344054 !important;
  color: #344054 !important;
  width: 40px ;
  height: 10px !important;
  border: none !important;
  border-radius: 8px;
  padding: 0 !important;
  }
  
  .custom-carousel .carousel-indicators button {
  
  background:#34405480 !important;
  color: #34405480  !important;
  width: 10px ;
  height: 10px !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 50%;
  }
  
  
  /* .custom-carousel .carousel-indicators {
  bottom: -30px !important;
  
  
  } */
  .slick-slide {
    transition: transform 0.5s ease, margin-top 0.5s ease;
    opacity: 0.8;
    transform: translateY(30px); 
  }
  
  .slick-center {
    transform: translateY(0) scale(1.1); 
    opacity: 1;
  }
  
  .opinion-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .detail-block {
    display: none;
  }
  
  .slick-center .detail-block {
    display: flex; /* Show detail block for centered slide */
  }

  .slick-slide:hover {
    transform: scale(1.05);
  }
  

  
 .actual-navbar .btn{
  width: 110px;
  }

  .product-image{
    width:130px;
  }

  @media (max-width: 1920px) {
  
    .hero-section{
      margin-bottom: 120px;
    }
    .actual-navbar .btn{
    width: 150px !important;
    }
    
    
    }
    
  @media (max-width: 1600px) {
  
    .hero-section{
      margin-bottom: 120px;
    }
    .actual-navbar .btn{
    width: auto !important;
    }
    
    
    }

    @media (max-width: 1450px) {
  
      .hero-section{
        margin-bottom: 100px;
      }
      .actual-navbar .btn{
        width: auto !important;
        }
      
      
      }

@media (max-width: 1200px) {
.nav-link{
  
  font-size: 16px;
  
}
.actual-navbar{
  margin-left: 1rem;
  margin-right: 1rem;
}
.actual-navbar .btn{
width: auto !important;
  font-size: 16px;
}
.product-image{
  width:100px;
}

}
@media (max-width: 768px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px; 
  }
  
  h3 {
    font-size: 16px;
   
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 14px;
  }
  h6{
    font-size: 11px;
  }
  p {
    font-size: 10px;
  }
  
  .hero-section{
    margin-bottom: 65px;
  }

  input::placeholder{
  
    font-size: 14px;
}
.btn{
  
  font-size: 14px;
  padding: 6px 12px;
}

.nav-link{
  
  font-size: 15px;
}
.actual-navbar{
  margin-left: 1rem;
  margin-right: 1rem;
}
.actual-navbar .btn{
  width: 90px;
    font-size: 16px;
  }

  .text-group {
    
    gap: 1rem;
  }
}